import { useQuery } from "@tanstack/react-query";
import { getModelValue } from "../../rich-text/useRichTextModelQuantities";
import { sapienAxios } from "@/inertia-utils/hooks";
import { useGetContentBlockWithChildren } from "@/Pages/DesignStudio/Design/useContentBlockState";
import { ContentBlockShape, ModelVariableUnit, VariableValue } from "@/models";
import { FormattedChartValue } from "@/modules/shared/charts/SharedTypes";
import { ChartType } from "@/Pages/Admin/editor/charts-and-tables/chart-wizard/ChartWizardReducer";
import { useFormatVariableValue } from "@/hooks";
import { abbrNum } from "@/modules/shared/charts/chart-util";
import { ThemeObject } from "@/styles";
import { content } from "@/Pages/Forum/Thread";

type ContentBlockVariableValue = VariableValue & {
    label: string;
    unit: ModelVariableUnit;
    is_integer: boolean;
    theme: ThemeObject;
};

async function getChartValues(contentBlockId: string) {
    const response = await sapienAxios.get<
        {
            values: { [index: string]: ContentBlockVariableValue[] };
            contentBlock: ContentBlockShape;
        },
        "get-chart-values"
    >("get-chart-values", {
        contentBlock: contentBlockId,
    });
    return response.data;
}

export const useGetChartValues = (contentBlockId: string) => {
    const formatVariableValue = useFormatVariableValue();
    const formatChartValues = (
        values: ContentBlockVariableValue[],
        contentBlock: ContentBlockShape,
    ): FormattedChartValue[] => {
        return values
            .map((variableValue, idx) => {
                let chartType: ChartType = contentBlock.content_block_type
                    .split("_")[0]
                    ?.toLowerCase() as ChartType;
                const value: FormattedChartValue = {
                    x: variableValue.time_index,
                    y: variableValue.numerical_value,
                    label: variableValue.label || "",
                    theme: contentBlock?.theme,
                    variable_id: variableValue.model_variable_id,
                    chartType,
                    displayText: formatVariableValue(
                        variableValue.unit,
                        variableValue.numerical_value,
                        variableValue.is_integer,
                        (value) =>
                            contentBlock.theme?.abbreviateYAxis !== false
                                ? abbrNum(value, [1, 1, 1, 0])
                                : value > 10000
                                  ? value.toLocaleString()
                                  : Math.round(value).toString(),
                    ),
                };
                return value;
            })
            .sort((a, b) => {
                return a.x > b.x ? 1 : -1;
            });
    };

    const { data, isPending } = useQuery({
        queryKey: ["chartValues", contentBlockId],
        queryFn: async (): Promise<{
            data: {
                [index: string]: FormattedChartValue[];
            };
            contentBlock: ContentBlockShape;
        }> => {
            const { values, contentBlock } =
                await getChartValues(contentBlockId);

            if (
                !!contentBlock.chart_data &&
                Object.keys(contentBlock.chart_data).length !== 0
            ) {
                return {
                    data: contentBlock.chart_data as {
                        [index: string]: FormattedChartValue[];
                    },
                    contentBlock,
                };
            }
            return {
                data: Object.keys(values).reduce((acc, key) => {
                    const valuesArray = values[key];
                    const childBlock = contentBlock.contentBlocks.find(
                        (child) =>
                            child.id === valuesArray[0]?.content_block_id,
                    );

                    return {
                        ...acc,
                        [key]: formatChartValues(valuesArray, childBlock),
                    };
                }, {}),
                contentBlock,
            };
        },
        initialData: { data: {}, contentBlock: {} as ContentBlockShape },
    });

    return {
        data: data.data,
        chartType: Object.values(data.data)?.[0]?.[0]?.chartType,
        contentBlock: data.contentBlock,
        isPending,
    };
};
