import { ContentBlockShape } from "@/models";
import { widthToTailwindFraction } from "@/styles/ThemeObjects";
import { cn } from "@/util";
import React from "react";
import { isDark } from "@/tailwind-colors/util";
import { themeToStyleVariables } from "@/tailwind-colors/themePropertyToStyleVariable";

const ChartWrapperComponent = ({
    children,
    contentBlock,
}: {
    children: React.ReactNode;
    contentBlock: ContentBlockShape;
}) => {
    const styles: React.CSSProperties = {
        width: `w-${widthToTailwindFraction[contentBlock.theme.width]}`,
    };

    if (contentBlock.theme?.backgroundColor !== "transparent") {
        styles.backgroundColor = contentBlock.theme.backgroundColor;
    }

    const isDarkBg = !!styles.backgroundColor && isDark(styles.backgroundColor);

    const styleVariables = {
        ...styles,
        ...themeToStyleVariables(contentBlock.theme),
    };

    return (
        <div
            className={cn(
                `m-2 flex flex-col space-y-2 rounded-md border border-slate-200 bg-slate-50/50
                transition-all data-[is-dark=true]:border-slate-800
                data-[is-dark=true]:bg-slate-800/25 h-full w-full`,
                contentBlock.theme.tailwindClasses,
            )}
            data-content-block-id={contentBlock.id}
            style={styleVariables}
            data-is-dark={isDarkBg}
        >
            <div
                className="m-2 mb-4 text-center text-sm font-semibold text-slate-900 transition-all
                    data-[is-dark=true]:text-slate-300 sm:mb-2 md:mb-6 2xl:mb-4"
                data-is-dark={isDarkBg}
            >
                {contentBlock.title || ""}
            </div>
            {children}
        </div>
    );
};
export const ChartWrapper = React.memo(ChartWrapperComponent);

const CardWidgetWrapperComponent = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    return (
        <div
            className="interactive-widget flex flex-col rounded-md border border-slate-200
                bg-slate-500/50 transition-all dark:border-slate-800 dark:bg-slate-800/25"
        >
            {children}
        </div>
    );
};
export const CardWidgetWrapper = React.memo(CardWidgetWrapperComponent);

const NestedCardWidgetWrapperComponent = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    return (
        <div
            className="interactive-widget flex flex-col rounded-md border border-slate-200/50
                bg-slate-100/50 transition-all dark:border-slate-800/50 dark:bg-slate-900"
        >
            {children}
        </div>
    );
};
export const NestedCardWidgetWrapper = React.memo(
    NestedCardWidgetWrapperComponent,
);

const ScrollableWrapperComponent = ({
    children,
    containerRef,
}: {
    children: React.ReactNode;
    containerRef?: React.MutableRefObject<any>;
}) => {
    return (
        <div
            ref={containerRef}
            className="interactive-widget relative h-full w-full overflow-auto transition-all
                scrollbar-thin scrollbar-track-slate-200/75 scrollbar-thumb-slate-300/75
                scrollbar-track-rounded-full scrollbar-thumb-rounded-full
                scrollbar-corner-rounded-full dark:scrollbar-track-slate-800
                dark:scrollbar-thumb-slate-700/75"
        >
            {children}
        </div>
    );
};
export const ScrollableWrapper = React.memo(ScrollableWrapperComponent);
