import React, { FC, ReactNode, useEffect, useState } from "react";
import {
    ThemeObject,
    Display,
    AlignItems,
    AlignContent,
    JustifyItems,
    JustifyContent,
    FlexWrap,
    FlexDirection,
} from "../ThemeObjects";
import { ContentBlockEvents } from "./ContentBlockEvents";
import { StyledUniversalDiv } from "./Universal";
import { cn } from "@/util";
import { themeToStyleVariables } from "@/tailwind-colors/themePropertyToStyleVariable";
import { useGetContentBlockWithChildren } from "@/Pages/DesignStudio/Design/useContentBlockState";

type StyledBoxProps = ContentBlockEvents & {
    theme: Partial<ThemeObject>;
    children?: ReactNode;
    contentBlockId?: string;
};

// function testImage(url): Promise<boolean> {
//     console.log(url, typeof url);
//     if (!url || typeof url !== "string") return Promise.resolve(false);
//     return new Promise((resolve) => {
//         const imgElement = new Image();
//         imgElement.addEventListener("load", () => resolve(true));
//         imgElement.addEventListener("error", () => resolve(false));
//         imgElement.src = url;
//     });
// }

export const StyledBox: FC<StyledBoxProps> = ({
    theme,
    children,
    contentBlockId,
    onClick,
    onHover,
    title,
}) => {
    const [numberOfRetriesRemaining, setNumberOfRetriesRemaining] = useState(5);
    const [src, setSrc] = useState(theme.backgroundImage);

    useEffect(() => {
        if (numberOfRetriesRemaining >= 0) {
            setSrc(() => theme.backgroundImage);
        } else {
            setSrc(
                () =>
                    "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/fYVikfRxPBgvFoJjOLNqRIRjrHt4SOeEYLZHBQ7C.png",
            );
        }
    }, [numberOfRetriesRemaining]);

    useEffect(() => {
        setNumberOfRetriesRemaining(5);
        setSrc(() => theme?.backgroundImage);
    }, [theme?.backgroundImage]);

    // const retry = useCallback(() => {
    //     if (numberOfRetriesRemaining >= 0) {
    //         setSrc(() => "");
    //         setNumberOfRetriesRemaining((previousNumber) => {
    //             return previousNumber - 1;
    //         });
    //     }
    // }, [numberOfRetriesRemaining, theme?.backgroundImage]);
    const hasPaddingClasses = theme.tailwindClasses?.find(
        (cls) =>
            cls.includes("p-") || cls.includes("px-") || cls.includes("py-"),
    );
    const paddingKeys = [
        "pt",
        "pr",
        "pb",
        "pl",
        "px",
        "py",
    ] as (keyof ThemeObject)[];
    const filteredTheme = Object.keys(theme).reduce((acc, key) => {
        if (
            paddingKeys.includes(key as keyof ThemeObject) &&
            hasPaddingClasses
        ) {
            return acc;
        }

        if (key === "backgroundImage") return acc;

        return {
            ...acc,
            [key]: theme[key],
        };
    }, {} as Partial<ThemeObject>);

    const fixedTheme = { ...filteredTheme };
    const styleVariables = themeToStyleVariables(theme);

    // if (Object.keys(styleVariables).length > 0) {
    //     return (
    //         <div
    //             data-content-block-id={contentBlockId}
    //             data-content-block-type={title || "Box"}
    //             onClick={"function" === typeof onClick ? onClick : (evt) => {}}
    //             className={`gap-2 ${cn(theme?.tailwindClasses)}`}
    //             style={{
    //                 ...(styleVariables as React.CSSProperties),
    //             }}
    //         >
    //             <pre>{JSON.stringify({theme, src}, null, 4)}</pre>
    //             {children}
    //             {!!theme.backgroundImage && (
    //                 <img
    //                     className="m-0 h-0 w-0 p-0 opacity-0"
    //                     src={src as string}
    //                     // onError={retry}
    //                 />
    //             )}
    //         </div>
    //     );
    // }

    const withChildren = useGetContentBlockWithChildren(contentBlockId);

    const isChartContiner = withChildren?.contentBlocks?.some(
        (child) => child.content_block_type === "CHART_CONTAINER",
    );

    return (
        <>
            <StyledUniversalDiv
                crossOrigin="anonymous"
                {...fixedTheme}
                data-content-block-id={contentBlockId}
                data-content-block-type={title || "Box"}
                onClick={"function" === typeof onClick ? onClick : (evt) => {}}
                className={`gap-2 flex ${cn(
                    theme?.tailwindClasses,
                    isChartContiner &&
                        "!grid !grid-cols-1 !gap-4 md:!grid-cols-2 data-[has-at-least-three-children=true]:xl:!grid-cols-3",
                )}`}
                style={{
                    ...(styleVariables as React.CSSProperties),
                }}
                data-has-at-least-three-children={
                    withChildren?.contentBlocks?.length >= 3
                }
            >
                {children}
                {!!theme.backgroundImage && (
                    <img
                        className="my-0 mx-auto w-full p-0 object-cover object-top max-h-full max-w-full"
                        src={src as string}
                        // onError={retry}
                    />
                )}
            </StyledUniversalDiv>
        </>
    );
};

export const defaultBoxTheme: Partial<ThemeObject> = {
    color: "#000000",
    backgroundColor: "rgba(0,0,0,0)",
    borderColor: "#000000",
    borderWidth: 0,
    borderRadius: 0,
    borderStyle: "solid",
    pt: 3,
    pr: 3,
    pb: 3,
    pl: 3,
    mt: 3,
    mr: 3,
    mb: 3,
    ml: 3,
    // width: ["auto", "auto", "auto", "auto"],
    display: Display.flex,
    flexDirection: FlexDirection.row,
    flexWrap: FlexWrap.nowrap,
    alignItems: AlignItems.normal,
    alignContent: AlignContent.normal,
    justifyItems: JustifyItems.normal,
    justifyContent: JustifyContent.normal,
};
