import React, { useMemo } from "react";
import {
    DomainTuple,
    ForAxes,
    InterpolationPropType,
    VictoryArea,
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryGroup,
    VictoryLegend,
    VictoryLine,
    VictoryPie,
    VictoryStack,
    VictoryTheme,
} from "victory";
import { useModelSuiteColorModeClassAtom } from "@/Layouts/model-suite";
import { InteractiveModuleChartType } from "@/Pages/InteractiveModule/types";
import { ChartType } from "@/Pages/Admin/editor/charts-and-tables/chart-wizard/ChartWizardReducer";
import { FormattedChartValue } from "@/modules/shared/charts/SharedTypes";
import { ContentBlockShape } from "@/models";
import { useGetChartValues } from "./useGetChartValues";
import { useGetContentBlockWithChildren } from "@/Pages/DesignStudio/Design/useContentBlockState";

const colorScaleMaterial = [
    "#F4511E",
    "#00796B",
    "#8BC34A",
    "#DCE775",
    "#c33409",
    "#006064",
    "#FFF59D",
];

const colorScaleQualitative = [
    "#254b9b", // "#334d5c",
    "#45b29d",
    "#efc94c",
    "#e27a3f",
    "#df5a49",
    "#4f7da1",
    "#55dbc1",
    "#efda97",
    "#e2a37f",
    "#df948a",
];

const colorScaleCool = ["#2746b9", "#31bb76", "#0b69d4", "#60e83b", "#2794db"];

export const tickFormat = (tick) => {
    if (tick >= 1000000) {
        return `${tick / 1000000}m`;
    } else if (tick >= 1000) {
        return `${tick / 1000}k`;
    }
    return tick;
};

const RenderChartComponent = ({
    stacked,
    interpolation,
    contentBlock,
}: {
    chartValues: { [index: string]: FormattedChartValue[] };
    chartType: ChartType;
    stacked: boolean;
    interpolation: string;
    contentBlock: ContentBlockShape;
}) => {

    const { data: chartValues, chartType, isPending } = useGetChartValues(contentBlock.id);
    
    const colorModeClass = useModelSuiteColorModeClassAtom();

    const color = useMemo(() => {
        return colorModeClass !== "dark" ? "#1e293b" : "#cbd5e1";
    }, [colorModeClass]);

    const maxY = useMemo(() => {

        return chartType !== "pie" && Object.values(chartValues)?.length > 0
            ? Math.max(
                  ...Object.values(chartValues)?.map((chartValueArray) =>
                      Math.max(...chartValueArray?.map((value) => value.y)),
                  ),
              )
            : 0;
    }, [chartType, chartValues]);

    const minY = useMemo(() => {
        return chartType !== "pie" && Object.values(chartValues)?.length > 0
            ? Math.min(
                  ...Object.values(chartValues).map((chartValueArray) =>
                      Math.min(...chartValueArray.map((value) => value.y)),
                  ),
              )
            : null;
    }, [chartType, chartValues]);

    const domain = useMemo<ForAxes<DomainTuple>>(() => {
        return minY !== null && minY == maxY
            ? minY == 0
                ? [0, 1]
                : minY > 0
                  ? [0, minY]
                  : [minY, 0]
            : null;
    }, [minY, maxY]);

    const width = useMemo(() => {
        return Object.keys(chartValues)?.length > 0
            ? Math.max(450, 60 * Object.keys(chartValues).length)
            : 450;
    }, [chartValues]);

    const filteredChartValuesArray = useMemo(() => {
        return (
            Object.values(chartValues)?.filter(
                (valueArray) => valueArray.length > 0,
            ) ?? []
        );
    }, [chartValues]);

    const legendData = useMemo(() => {
        return filteredChartValuesArray.map((valueArray) => ({
            name: valueArray[0]?.label ?? "",
        }));
    }, [filteredChartValuesArray]);

    const dependentAxisStyle = useMemo(() => {
        return {
            tickLabels: {
                padding: 5,
                fontSize: 10,
                stroke: color,
                fill: color,
            },
            grid: { strokeOpacity: 0.1, stroke: color },
            axis: { strokeOpacity: 0.25, stroke: color },
            ticks: { strokeOpacity: 0.25, stroke: color },
        };
    }, [color]);

    const independentAxisStyle = useMemo(() => {
        return {
            axisLabel: {
                padding: 20,
                fontSize: 10,
                stroke: color,
                fill: color,
            },
            tickLabels: {
                padding: 2,
                fontSize: 10,
                stroke: color,
                fill: color,
            },
            grid: { strokeOpacity: 0.1, stroke: color },
            axis: { strokeOpacity: 0.25, stroke: color },
            ticks: { strokeOpacity: 0.25, stroke: color },
        };
    }, [color]);

    const legendStyle = useMemo(() => {
        return {
            labels: { fontSize: 14, stroke: color, fill: color },
        };
    }, [color]);

    if (!(filteredChartValuesArray?.length > 0)) {
        return (
            <div className="m-3 h-[400px] animate-pulse rounded-md bg-slate-600/10 dark:bg-slate-100/10"></div>
        );
    }

    const containerClassName = "flex flex-col items-center px-4 w-full " + (isPending ? "animate-pulse" : "");
    const legendWrapperClassName = "flex justify-center";

    switch (chartType) {
        case "area":
            return (
                <div className={containerClassName + " -mt-10"}>
                    <VictoryChart
                        theme={VictoryTheme.material}
                        width={width ?? 450}
                        domainPadding={{ y: 10 }}
                    >
                        {!stacked ? (
                            <VictoryGroup
                                colorScale={colorScaleMaterial}
                                style={{
                                    data: { strokeWidth: 5, fillOpacity: 0.4 },
                                }}
                            >
                                {filteredChartValuesArray.map(
                                    (valueArray, i) => (
                                        <VictoryArea
                                            key={i}
                                            data={valueArray.map((value) => ({
                                                x: value.x,
                                                y: value.y,
                                            }))}
                                        />
                                    ),
                                )}
                            </VictoryGroup>
                        ) : (
                            <VictoryStack
                                colorScale={colorScaleMaterial}
                                style={{
                                    data: { strokeWidth: 5, fillOpacity: 0.4 },
                                }}
                            >
                                {filteredChartValuesArray.map(
                                    (valueArray, i) => (
                                        <VictoryArea
                                            key={i}
                                            data={valueArray.map((value) => ({
                                                x: value.x,
                                                y: value.y,
                                            }))}
                                        />
                                    ),
                                )}
                            </VictoryStack>
                        )}
                        <VictoryAxis
                            dependentAxis
                            style={dependentAxisStyle}
                            tickFormat={maxY > 100000 ? tickFormat : null}
                            domain={domain ?? null}
                        />
                        <VictoryAxis
                            label="Time"
                            style={independentAxisStyle}
                        />
                    </VictoryChart>
                    <div className={legendWrapperClassName}>
                        <VictoryLegend
                            theme={VictoryTheme.material}
                            orientation="vertical"
                            gutter={20}
                            colorScale={colorScaleMaterial}
                            data={legendData}
                            style={legendStyle}
                            height={filteredChartValuesArray.length * 30 + 16}
                        />
                    </div>
                </div>
            );
        case "line":
            return (
                <div className={containerClassName + " -mt-10"}>
                    <VictoryChart
                        theme={VictoryTheme.material}
                        width={width ?? 450}
                        domainPadding={{ y: 15 }}
                    >
                        <VictoryGroup
                            colorScale={colorScaleQualitative}
                            style={{
                                data: { strokeWidth: 5 },
                            }}
                        >
                            {filteredChartValuesArray.map((valueArray, i) => (
                                <VictoryLine
                                    key={i}
                                    data={valueArray.map((value) => ({
                                        x: value.x,
                                        y: value.y,
                                    }))}
                                    interpolation={
                                        (interpolation || "linear") as InterpolationPropType
                                    }
                                />
                            ))}
                        </VictoryGroup>
                        <VictoryAxis
                            dependentAxis
                            style={dependentAxisStyle}
                            tickFormat={maxY > 100000 ? tickFormat : null}
                            domain={domain ?? null}
                        />
                        <VictoryAxis
                            label="Time"
                            style={independentAxisStyle}
                        />
                    </VictoryChart>
                    <div className={legendWrapperClassName}>
                        <VictoryLegend
                            theme={VictoryTheme.material}
                            orientation="vertical"
                            gutter={20}
                            colorScale={colorScaleQualitative}
                            data={legendData}
                            style={legendStyle}
                            height={filteredChartValuesArray.length * 30 + 16}
                        />
                    </div>
                </div>
            );
        case "pie":
        case "doughnut":
            return (
                <div className={containerClassName}>
                    <VictoryPie
                        theme={VictoryTheme.material}
                        width={270}
                        height={210}
                        data={filteredChartValuesArray
                            .reduce(
                                (map, valueArray) => [...map, ...valueArray],
                                [],
                            )
                            .map((value) => ({
                                x: value.x,
                                y: value.y,
                                formattedValue: value.displayText,
                            }))}
                        colorScale="warm"
                        labels={({ datum }: { datum: { formattedValue: string } }) => {
                            return `${datum.formattedValue}`}
                        }
                        labelRadius={60}
                        cornerRadius={2}
                        padAngle={2}
                        innerRadius={20}
                        style={{
                            labels: {
                                fontSize: 6,
                                fill: "white",
                                padding: 10,
                            },
                            data: {
                                strokeOpacity: 0.25,
                            },
                        }}
                        padding={{
                            top: 0,
                            bottom: 0,
                            left: 40,
                            right: 40,
                        }}
                    />
                    <div className={legendWrapperClassName}>
                        <VictoryLegend
                            theme={VictoryTheme.material}
                            orientation="vertical"
                            gutter={20}
                            colorScale="warm"
                            data={legendData}
                            style={legendStyle}
                            height={filteredChartValuesArray.length * 30 + 16}
                        />
                    </div>
                </div>
            );
        case "bar":
            return (
                <div className={containerClassName + " -mt-10"}>
                    <VictoryChart
                        theme={VictoryTheme.material}
                        width={width ?? 450}
                    >
                        {!stacked ? (
                            <VictoryGroup
                                colorScale={colorScaleCool}
                                offset={
                                    width /
                                    (filteredChartValuesArray.length *
                                        filteredChartValuesArray[0].length *
                                        2)
                                }
                            >
                                {filteredChartValuesArray.map(
                                    (valueArray, i) => (
                                        <VictoryBar
                                            key={i}
                                            data={valueArray.map((value) => ({
                                                x: value.x,
                                                y: value.y,
                                            }))}
                                        />
                                    ),
                                )}
                            </VictoryGroup>
                        ) : (
                            <VictoryStack colorScale={colorScaleCool}>
                                {filteredChartValuesArray.map(
                                    (valueArray, i) => (
                                        <VictoryBar
                                            key={i}
                                            data={valueArray.map((value) => ({
                                                x: value.x,
                                                y: value.y,
                                            }))}
                                        />
                                    ),
                                )}
                            </VictoryStack>
                        )}
                        <VictoryAxis
                            dependentAxis
                            style={dependentAxisStyle}
                            tickFormat={maxY > 100000 ? tickFormat : null}
                            domain={domain ?? null}
                        />
                        <VictoryAxis
                            label="Time"
                            style={independentAxisStyle}
                        />
                    </VictoryChart>
                    <div className={legendWrapperClassName}>
                        <VictoryLegend
                            theme={VictoryTheme.material}
                            orientation="vertical"
                            gutter={20}
                            colorScale={colorScaleCool}
                            data={legendData}
                            style={legendStyle}
                            height={filteredChartValuesArray.length * 30 + 16}
                        />
                    </div>
                </div>
            );
        default:
            return <></>;
    }
};

export const ChartContainer = React.memo(RenderChartComponent);
